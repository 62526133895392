.hiag-shortcuts-view {
  .hiag-shortcut-button.button {
    border-radius: 50%;

    img {
      transform: rotate(0deg);
      transition: transform 0.2s;
    }

    &:hover img {
      transform: rotate(-5deg);
    }
  }

  .views-row:nth-child(even) .hiag-shortcut-button.button:hover {
    background-color: var(--secondary-color);
  }

  .views-row:nth-child(odd) .hiag-shortcut-button.button:hover {
    img {
      filter: drop-shadow(2px 2px 0px white);
    }
    .shortcut-title {
      color: white;
    }
  }
}

.is-front-page .region-backdrop #block-hiag-base-theme-header {
  position: fixed;
  filter: drop-shadow(5px 5px 0px white);

  .field--name-field-logo {
    animation-name: logoAnim;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    width: 320px;
    height: 320px;

    @media screen and (max-width: 1600px) {
      width: 260px;
      height: 260px;
    }

    @media screen and (max-width: 1200px) {
      width: 200px;
      height: 200px;
    }

    @media screen and (max-width: 800px) {
      width: 120px;
      height: 120px;
    }
  }

  @media screen and (max-width: 1600px) {
    filter: drop-shadow(4px 4px 0px white);
  }

  @media screen and (max-width: 1200px) {
    filter: drop-shadow(2px 2px 0px white);
  }

  @media screen and (max-width: 800px) {
    filter: drop-shadow(1px 1px 0px white);
  }
}

@keyframes logoAnim {
  0% {
    transform: translateX(-400px) rotate(-360deg);
  }
  100% {
    transform: translateX(0px) rotate(0deg);
  }
}

#block-hiag-base-theme-header {
  position: absolute;
  filter: drop-shadow(2px 2px 0px white);
  left: 0;
  top: 0;
  margin: var(--global-margin);

  .field--name-field-logo {
    width: 150px;
    height: 150px;

    @media screen and (max-width: 800px) {
      width: 120px;
      height: 120px;
    }
  }

  @media screen and (max-width: 800px) {
    filter: drop-shadow(1px 1px 0px white);
  }

  .hiag-main-title-container {
    visibility: hidden;
  }
}

.hiag-footer-container {
  border-top: 5px solid var(--secondary-color);
}

[type="text"]:focus,
[type="password"]:focus,
[type="date"]:focus,
[type="datetime"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="week"]:focus,
[type="email"]:focus,
[type="number"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="url"]:focus,
[type="color"]:focus,
textarea:focus {
  box-shadow: inset 0 0 0px 2px var(--secondary-color);
}

.hiag-search-suggestions-container ul {
  margin-right: 1.25rem;
}

.node--type-school-article {
  .field--name-field-image {
    width: 100%;
    height: auto;
    * {
      width: 100%;
      height: auto;
    }
  }

  .hiag-article-header {
    flex-direction: column;
    align-items: center;

    .field--name-title {
      font-size: 120%;
      font-weight: bold;
    }

    * {
      color: var(--white);
    }
  }
}

.card-divider:last-child {
  border-radius: 0 0 1rem 1rem;
}

.view-display-id-block_event_school_accordion {
  .view-content {
    display: flex;
    flex-direction: column;

    .views-row {
      padding: var(--global-margin);
    }
  }
}

.teaser-label {
  width: 160px;

  > *:nth-child(3) {
    margin-left: 24px;
    &:before {
      position: absolute;
      content: "";
      font-size: 42px;
      width: 16px;
      height: 0px;
      border: 1px solid var(--primary-color);
      transform: translate(-20px, 25px);
    }
  }
}

.tabs-panel > .node {
  min-height: 222px;
}

.hiag-top-bar-wrapper {
  background: var(--primary-color);

  .title-bar {
    padding: 0.125rem;
    background: var(--primary-color);
  }
}

.menu li a[href="/schule"],
.menu li a[href="/"] {
  color: var(--secondary-color);

  &:hover {
    color: var(--secondary-color-darken-1);
  }
}

.drilldown.menu li a[href="/schule"],
.drilldown.menu li a[href="/"] {
  color: var(--primary-color);
  background-color: var(--secondary-color-transparent-4) !important;

  &:hover {
    color: var(--primary-color-darken-1);
    background-color: var(--secondary-color);
  }
}

@media print, screen and (min-width: 64em) {
  .node--view-mode-full .page-sub-content:before {
    content: "";
    position: absolute;
    height: calc(100% + 0.75rem);
    width: calc(100% + 8rem);
    top: -0.375rem;
    right: -0.375rem;
    background: #fefefe;
    border: 2px solid var(--primary-color);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 1rem;
  }
}

footer :where(.card .field__label, .responsive-blocks-title) {
  color: #fefefecc !important;
}

.hiag-main-content-container {
  padding-bottom: calc(var(--current-footer-height) + var(--global-margin));
}

footer.hiag-footer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 500;
  transform: translateY(360px);
  cursor: pointer;
  box-shadow: 0px 0px 30px 20px #00000033;

  #block-footerinhalte {
    margin: 0 2.1rem;
  }

  &.footer-is--open,
  &.footer-is--at-bottom.footer-is--closed {
    transform: translateY(0px);
    transition:
      transform 800ms,
      box-shadow 800ms;
    box-shadow: 0px 0px 40px 30px #00000055;
  }
  &.footer-is--closed {
    --translate: calc(var(--current-footer-height) - 54px);
    transform: translateY(var(--translate));
    transition:
      transform 800ms,
      box-shadow 800ms;
  }
}

.hiag-tabs .tabs-title:not(.is-active) {
  a {
    border-width: 2px !important;
  }

  &:hover,
  &:focus {
    a {
      background-color: var(--secondary-color-transparent-4) !important;
    }
  }
}

.menu-item.is-active:hover > a,
.is-submenu-item.is-active:hover > a {
  background-color: var(--secondary-color) !important;
}

.is-submenu-item.is-active:not(:hover) > a {
  color: var(--white) !important;
}

.menu-item > a,
.is-submenu-item {
  transition: background-color 0.8s;
}

@media screen and (min-width: 1024px) {
  .tabs:not(.hiag-menu-local-tasks-tabs) {
    width: calc(#{100% / 6 * 5} - 8rem);
  }
}

footer h4 {
  color: var(--secondary-color) !important;
}

.hiag-main-wrapper {
  background-image: url("https://ruefenach.hi-egov.ch/sites/default/files/2024-07/ruefenach_watermark_0.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 172vw;
}

@media screen and (max-width: 1024px) {
  .hiag-main-wrapper {
    background-position: top 66%;
    background-size: 266vw;
  }
}

.node--type-config-responsive-blocks .tabs-panel {
  max-height: 50vh;
  overflow-y: auto;
}

.node--type-school-article.node--view-mode-hiag-card.card {
  background: transparent;
}

.region-right-off-canvas {
  .hiag-branding-link {
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    align-items: center;
    margin: auto;

    .field--name-field-website-name {
      font-size: 1.6rem;
      line-height: 1.2;
      padding: 0 !important;
      margin: 0 !important;
    }

    .field--name-field-welcome-text {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

@import "./field-iframe-f4d";
